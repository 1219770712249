<template>
  <validation-provider
    #default="{ errors }"
    :name="label"
    :vid="label"
    :rules="rules"
  >
    <b-form-group
      :label="label"
      :label-for="name"
    >
      <v-select
        key="value"
        v-model="inputValue"
        :name="name"
        :options="options"
        :placeholder="placeholder"
        label="text"
        :state="errors.length > 0 ? false:null"
        :disabled="disabled"
        @change="onChange"
      >
        <div slot="no-options">
          Nenhum(a) {{ label }} encontrado(a).
        </div>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>

import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'I2FormVueSelect',
  components: {
    ValidationProvider,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Object],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    rules: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      inputValue: '',
      selected: null,
      required,
    }
  },
  watch: {
    value(data) {
      this.inputValue = data
    },
    inputValue(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    onChange(data) {
      this.$emit('onChange', data)
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
