import Vue, {} from 'vue'
import VueCompositionAPI, { provide } from '@vue/composition-api'
import { localize } from 'vee-validate'
import ptBR from 'vee-validate/dist/locale/pt_BR.json'

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

import VueTheMask from 'vue-the-mask'
import VueApexCharts from 'vue-apexcharts'
import money from 'v-money'
import router from './router'
import store from './store'
import App from './App.vue'
import './libs/users.js'
// Global Components
import './global-components'

// forms components
// 3rd party plugins
import '@/libs/portal-vue'

import './libs/FormComponents'
import './libs/Search'
import './libs/fontawesome'
import './components/i2/notification/toast'
import './registerServiceWorker'
import Validation from './libs/validation'

import VueViewer from 'v-viewer'

// Global registration

Vue.use(Validation)
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
Vue.use(money, {
  precision: 2,
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  suffix: ' #',
  masked: true,
})
Vue.use(VueTheMask)
// BSV Plugin Registration

Vue.use(VueMoment)
moment.defaultFormat = {
  pt_BR: 'DD/MM/YYYY',
}
moment.locale('pt_BR')
Vue.use(VueMoment, { moment })

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VueViewer)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
localize('pt_BR', ptBR)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
Vue.config.dateFormat = moment.defaultFormat
