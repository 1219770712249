import Vue from 'vue'

const ApiSearch = {
  meta: { page: 1, per_page: 25 },
  async get(endPoint, meta, search, includes) {
    return window.http.get(this.buildUrl(endPoint, meta, search, includes))
  },
  getAsync(endPoint, meta, search, includes) {
    return window.http.get(this.buildUrl(endPoint, meta, search, includes))
  },
  parseSearchString(search) {
    const currentSearch = []
    // eslint-disable-next-line no-restricted-syntax
    for (const el in search) {
      if (search[el] !== null) {
        let currentValue = this.getSearchValue(search[el])
        if (currentValue.value) {
          currentValue = currentValue.value
        }
        currentSearch.push(`${el}:${currentValue}`)
      }
    }

    return currentSearch.join(';')
  },

  getSearchValue(value) {
    let currentValue = value
    if (currentValue instanceof Array) {
      const partialValue = []
      // eslint-disable-next-line no-restricted-syntax
      for (const part of currentValue) {
        let partValue = part
        if (part.value) {
          partValue = part.value
        }
        partialValue.push(partValue)
      }
      return partialValue.join(',')
    }
    if (currentValue.value) {
      currentValue = currentValue.value
    }

    return currentValue
  },
  buildUrl(endPoint, meta, search, includes) {
    if (meta === null || meta === undefined) {
      meta = { page: 1, per_page: 25 }
    }
    const searchString = this.parseSearchString(search)
    let startQueryChar = '?'
    if (endPoint.indexOf('?') >= 0) {
      startQueryChar = '&'
    }
    let url = `${endPoint}${startQueryChar}page=${meta.page || 1}&per_page=${meta.per_page || 25}`
    const parts = []
    if (searchString !== '') {
      parts.push(`search=${searchString}`)
    }
    if (includes !== '') {
      parts.push(`include=${includes}`)
    }
    if (meta.sort_field) {
      parts.push(`sort_by=${meta.sort_field}`)
    }
    if (meta.sort_order) {
      parts.push(`sort_order=${meta.sort_order}`)
    }
    url += `&${parts.join('&')}`
    return url
  },
}
Vue.prototype.$search = ApiSearch
window.apiSearch = ApiSearch
export default ApiSearch
