const settings = [
  {
    path: '/settings/whatsapp',
    name: 'settings.whatsapp',
    component: () => import('@/views/settings/WhatsAppSettings.vue'),
    meta: {
      pageTitle: 'Configurações de WhatsApp',
      breadcrumb: [
        {
          text: 'WhatsApp',
          active: true,
        },
      ],
    },
  },
]

export default settings
