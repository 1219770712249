import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import { ValidationObserver } from 'vee-validate'
import I2FormInputDate from '@/components/i2/forms/elements/I2FormInputDate.vue'
import I2FormInput from '@/components/i2/forms/elements/I2FormInput.vue'
import I2FormVueSelect from '@/components/i2/forms/elements/I2FormVueSelect.vue'
import I2Grid from '@/components/i2/grid/Grid.vue'
import I2SideBar from '@/components/i2/container/I2SideBar'
import I2Divider from '@/components/i2/content/i2Divider'
import SModal from '@/components/i2/container/SModal.vue'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(I2FormInputDate.name, I2FormInputDate)
Vue.component(I2FormInput.name, I2FormInput)
Vue.component(I2FormVueSelect.name, I2FormVueSelect)
Vue.component(ValidationObserver.name, ValidationObserver)
Vue.component(I2Grid.name, I2Grid)
Vue.component(I2SideBar.name, I2SideBar)
Vue.component(I2Divider.name, I2Divider)
Vue.component(SModal.name, SModal)
