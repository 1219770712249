import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'

import password from '@/router/password'
import devices from '@/router/devices'
import users from '@/router/users'
import clients from '@/router/clients'
import settings from '@/router/settings'

Vue.use(VueRouter)

const availableRoutes = [
  {
    path: '/',
    name: 'root',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboards',
      breadcrumb: [
        {
          text: 'Resumo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/graphs',
    name: 'graphs',
    component: () => import('@/views/graphs/Graphs.vue'),
    meta: {
      pageTitle: 'Gráficos',
      breadcrumb: [
        {
          text: 'Gráficos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/maps',
    name: 'maps',
    component: () => import('@/views/maps/Maps.vue'),
    meta: {
      pageTitle: 'Gráficos',
      breadcrumb: [
        {
          text: 'Gráficos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tables',
    name: 'tables',
    component: () => import('@/views/tables/Tables.vue'),
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

devices.map(route => availableRoutes.push(route))
password.map(route => availableRoutes.push(route))
users.map(route => availableRoutes.push(route))
clients.map(route => availableRoutes.push(route))
settings.map(route => availableRoutes.push(route))

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: availableRoutes,
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'page-error-404' || to.name === 'login' || to.name === 'perdi-minha-senha'
  || to.name === 'recuperar-senha') {
    return next()
  }
  if (to.name && to.name.length && (to.name === '/' || to.name === 'login')) {
    if (!isUserLoggedIn()) {
      window.location.href = '/'
      return false
    }
  }
  next()
  return true
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
